// extracted by mini-css-extract-plugin
export var pwaBenefitWebsiteSection = "t_cJ";
export var pwaCardRowIconsSection = "t_cM";
export var pwaCycleDevelopmentSection = "t_cK";
export var pwaCycleDevelopmentSection__testimonial = "t_cL";
export var pwaDomainExpertiseSection = "t_cQ";
export var pwaFeaturedCaseSection = "t_cR";
export var pwaProjLogoSection = "t_cW";
export var pwaServiceAchievementsSection = "t_cX";
export var pwaServiceClientsSection = "t_cY";
export var pwaServicesCompaniesSection = "t_cN";
export var pwaServicesCycleDevelopmentSection = "t_cP";
export var pwaSuccessStoriesSection = "t_cT";
export var pwaTechStackSection = "t_cS";
export var pwaWhyChooseSection = "t_cV";